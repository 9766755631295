import './App.css'
import Home from './Home'
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import NFTPage from './NFTPage'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/:id" element={<NFTPage />}></Route>
          <Route path="*" exact={true} element={<Home />}></Route>
        </Routes>
      </div>
    </Router>
  )
}

export default App
