import './style.css'
import './App.css'
import CapsuleBig from './assets/platform_images/Capsule-big.png'
import CapsuleSmall from './assets//platform_images/capsule-small.png'

import React from 'react'
const Home = () => {
  return (
    <div className="container">
      <div className="capsule-div">
        <img
          id="tablet-image"
          alt="Capsule"
          src={CapsuleBig}
          style={{ width: '80%', height: 'auto' }}
        />
        <img
          id="mobile-image"
          alt="Capsule"
          src={CapsuleSmall}
          style={{ width: '385px', height: 'auto' }}
        />
      </div>
    </div>
  )
}
export default Home
