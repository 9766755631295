import './style.css'
import New from './assets/platform_images/new.png'
import Group30 from './assets/platform_images/Group 30.png'
import React, { useState } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import Modal from 'react-modal'
import { AiOutlineCloseSquare } from 'react-icons/ai'

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.60)',
  },
  content: {
    width: '80%',
    top: '52%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid white',
    borderRadius: '10px',
    backgroundColor: 'black',
    color: 'white',
    overflow: 'hidden',
  },
}
const Page1 = () => {
  let { id } = useParams()
  const [modalIsOpen, setIsOpen] = useState(true)
  const nftNames = [
    'ILP1BG1-SDC1LK',
    'GHP3BG10-SDC2LM',
    'ILP1BG6-SDC2LK',
    'ILP2BG7-SDC4LK',
    'TMP4BG12-SDC5LK',
    'ILP1BG5-SDC7LK',
    'ILP1BG6-SDC4LK',
    'ILP2BG7-SDC2LK',
    'NNP3BG11-SDC6LK',
    'SEP5BG0-SDC8LK',
    'NNP3BG15-SGC1LK',
    'SEP5BG0-SDC5LK',
    'NNP3BG11-SDC3LK',
    'ILP2BG7-SDC3LK',
    'NNP3BG15-SDC6LK',
    'SCP4BG15-SDC1LK',
    'ILP1BG5-SDC7LM',
    'SEP5BG0-SDC6LK',
    'SCP4BG15-SDC7LK',
    'ILP2BG2-SDC2LK',
    'ILP2BG7-SGC3LM',
    'NNP3BG15-SDC4LM',
    'ILP1BG1-SDC3LK',
    'ILP1BG6-SDC5LM',
    'SEP5BG0-SLC8LM',
    'SCP4BG15-SDC3LM',
    'ILP1BG1-SDC5LK',
    'TMP4BG12-SDC7LM',
    'ILP1BG1-SDC2LM',
    'ILP1BG1-SGC2LK',
    'GHP3BG10-SDC1LK',
    'GHP3BG10-SDC1LM',
    'GHP3BG10-SDC2LK',
    'GHP3BG10-SDC3LK',
    'GHP3BG10-SDC3LM',
    'GHP3BG10-SDC4LK',
    'GHP3BG10-SDC4LM',
    'GHP3BG10-SDC5LK',
    'GHP3BG10-SDC5LM',
    'GHP3BG10-SDC6LK',
    'GHP3BG10-SDC6LM',
    'GHP3BG10-SDC7LK',
    'GHP3BG10-SDC7LM',
    'GHP3BG10-SDC8LK',
    'GHP3BG10-SDC8LM',
    'GHP3BG10-SGC1LK',
    'GHP3BG10-SGC1LM',
    'GHP3BG10-SGC2LK',
    'GHP3BG10-SGC2LM',
    'GHP3BG10-SGC3LK',
    'GHP3BG10-SGC3LM',
    'GHP3BG10-SGC4LK',
    'GHP3BG10-SGC4LM',
    'GHP3BG10-SGC5LK',
    'GHP3BG10-SGC5LM',
    'GHP3BG10-SGC6LK',
    'GHP3BG10-SGC6LM',
    'GHP3BG10-SGC7LK',
    'GHP3BG10-SGC7LM',
    'GHP3BG10-SGC8LK',
    'GHP3BG10-SGC8LM',
    'GHP3BG10-SLC1LK',
    'GHP3BG10-SLC1LM',
    'GHP3BG10-SLC2LK',
    'GHP3BG10-SLC2LM',
    'GHP3BG10-SLC3LK',
    'GHP3BG10-SLC3LM',
    'GHP3BG10-SLC4LK',
    'GHP3BG10-SLC4LM',
    'GHP3BG10-SLC5LK',
    'GHP3BG10-SLC5LM',
    'GHP3BG10-SLC6LK',
    'GHP3BG10-SLC6LM',
    'GHP3BG10-SLC7LK',
    'GHP3BG10-SLC7LM',
    'GHP3BG10-SLC8LK',
    'GHP3BG10-SLC8LM',
    'ILP1BG6-SDC1LK',
    'ILP1BG6-SDC1LM',
    'ILP1BG6-SDC2LM',
    'ILP1BG6-SDC3LK',
    'ILP1BG6-SDC3LM',
    'ILP1BG6-SDC4LM',
    'ILP1BG6-SDC5LK',
    'ILP1BG6-SDC6LK',
    'ILP1BG6-SDC6LM',
    'ILP1BG6-SDC7LK',
    'ILP1BG6-SDC7LM',
    'ILP1BG6-SDC8LK',
    'ILP1BG6-SDC8LM',
    'ILP1BG6-SGC1LK',
    'ILP1BG6-SGC1LM',
    'ILP1BG6-SGC2LK',
    'ILP1BG6-SGC2LM',
    'ILP1BG6-SGC3LK',
    'ILP1BG6-SGC3LM',
    'ILP1BG6-SGC4LK',
    'ILP1BG6-SGC4LM',
    'ILP1BG6-SGC5LK',
    'ILP1BG6-SGC5LM',
    'ILP1BG6-SGC6LK',
    'ILP1BG6-SGC6LM',
    'ILP1BG6-SGC7LK',
    'ILP1BG6-SGC7LM',
    'ILP1BG6-SGC8LK',
    'ILP1BG6-SGC8LM',
    'ILP1BG6-SlC1LK',
    'ILP1BG6-SlC1LM',
    'ILP1BG6-SlC2LK',
    'ILP1BG6-SlC2LM',
    'ILP1BG6-SlC3LK',
    'ILP1BG6-SlC3LM',
    'ILP1BG6-SlC4LK',
    'ILP1BG6-SlC4LM',
    'ILP1BG6-SlC5LK',
    'ILP1BG6-SlC5LM',
    'ILP1BG6-SlC6LK',
    'ILP1BG6-SlC6LM',
    'ILP1BG6-SlC7L',
    'ILP1BG6-SlC7LM',
    'ILP1BG6-SlC8LK',
    'ILP1BG6-SlC8LM',
    'ILP2BG7-SDC1LK',
    'ILP2BG7-SDC1LM',
    'ILP2BG7-SDC2LM',
    'ILP2BG7-SDC3LM',
    'ILP2BG7-SDC4LM',
    'ILP2BG7-SDC5LK',
    'ILP2BG7-SDC5LM',
    'ILP2BG7-SDC6LK',
    'ILP2BG7-SDC6LM',
    'ILP2BG7-SDC7LK',
    'ILP2BG7-SDC7LM',
    'ILP2BG7-SDC8LK',
    'ILP2BG7-SDC8LM',
    'ILP2BG7-SGC1LK',
    'ILP2BG7-SGC1LM',
    'ILP2BG7-SGC2LK',
    'ILP2BG7-SGC2LM',
    'ILP2BG7-SGC3LK',
    'ILP2BG7-SGC4LK',
    'ILP2BG7-SGC4LM',
    'ILP2BG7-SGC5LK',
    'ILP2BG7-SGC5LM',
    'ILP2BG7-SGC6LK',
    'ILP2BG7-SGC6LM',
    'ILP2BG7-SGC7LK',
    'ILP2BG7-SGC7LM',
    'ILP2BG7-SGC8LK',
    'ILP2BG7-SGC8LM',
    'ILP2BG7-SLC1LK',
    'ILP2BG7-SLC1LM',
    'ILP2BG7-SLC2LK',
    'ILP2BG7-SLC2LM',
    'ILP2BG7-SLC3LK',
    'ILP2BG7-SLC3LM',
    'ILP2BG7-SLC4LK',
    'ILP2BG7-SLC4LM',
    'ILP2BG7-SLC5LK',
    'ILP2BG7-SLC5LM',
    'ILP2BG7-SLC6LK',
    'ILP2BG7-SLC6LM',
    'ILP2BG7-SLC7LK',
    'ILP2BG7-SLC7LM',
    'ILP2BG7-SLC8LK',
    'ILP2BG7-SLC8LM',
    'NNP3BG11-SDC1LK',
    'NNP3BG11-SDC1LM',
    'NNP3BG11-SDC2LK',
    'NNP3BG11-SDC2LM',
    'NNP3BG11-SDC3LM',
    'NNP3BG11-SDC4LK',
    'NNP3BG11-SDC4LM',
    'NNP3BG11-SDC5LK',
    'NNP3BG11-SDC5LM',
    'NNP3BG11-SDC6LM',
    'NNP3BG11-SDC7LK',
    'NNP3BG11-SDC7LM',
    'NNP3BG11-SDC8LK',
    'NNP3BG11-SDC8LM',
    'NNP3BG11-SGC1LK',
    'NNP3BG11-SGC1LM',
    'NNP3BG11-SGC2LK',
    'NNP3BG11-SGC2LM',
    'NNP3BG11-SGC3LK',
    'NNP3BG11-SGC3LM',
    'NNP3BG11-SGC4LK',
    'NNP3BG11-SGC4LM',
    'NNP3BG11-SGC5LK',
    'NNP3BG11-SGC5LM',
    'NNP3BG11-SGC6LK',
    'NNP3BG11-SGC6LM',
    'NNP3BG11-SGC7LK',
    'NNP3BG11-SGC7LM',
    'NNP3BG11-SGC8LK',
    'NNP3BG11-SGC8LM',
    'NNP3BG11-SLC1LK',
    'NNP3BG11-SLC1LM',
    'NNP3BG11-SLC2LK',
    'NNP3BG11-SLC2LM',
    'NNP3BG11-SLC3LK',
    'NNP3BG11-SLC3LM',
    'NNP3BG11-SLC4LK',
    'NNP3BG11-SLC4LM',
    'NNP3BG11-SLC5LK',
    'NNP3BG11-SLC5LM',
    'NNP3BG11-SLC6LK',
    'NNP3BG11-SLC6LM',
    'NNP3BG11-SLC7LK',
    'NNP3BG11-SLC7LM',
    'NNP3BG11-SLC8LK',
    'NNP3BG11-SLC8LM',
    'NNP3BG15-SDC1LK',
    'NNP3BG15-SDC1LM',
    'NNP3BG15-SDC2LK',
    'NNP3BG15-SDC2LM',
    'NNP3BG15-SDC3LK',
    'NNP3BG15-SDC3LM',
    'NNP3BG15-SDC4LK',
    'NNP3BG15-SDC5LK',
    'NNP3BG15-SDC5LM',
    'NNP3BG15-SDC6LM',
    'NNP3BG15-SDC7LK',
    'NNP3BG15-SDC7LM',
    'NNP3BG15-SDC8LK',
    'NNP3BG15-SDC8LM',
    'NNP3BG15-SGC1LM',
    'NNP3BG15-SGC2LK',
    'NNP3BG15-SGC2LM',
    'NNP3BG15-SGC3LK',
    'NNP3BG15-SGC3LM',
    'NNP3BG15-SGC4LK',
    'NNP3BG15-SGC4LM',
    'NNP3BG15-SGC5LK',
    'NNP3BG15-SGC5LM',
    'NNP3BG15-SGC6LK',
    'NNP3BG15-SGC6LM',
    'NNP3BG15-SGC7LK',
    'NNP3BG15-SGC7LM',
    'NNP3BG15-SGC8LK',
    'NNP3BG15-SGC8LM',
    'NNP3BG15-SlC1LK',
    'NNP3BG15-SlC1LM',
    'NNP3BG15-SlC2LK',
    'NNP3BG15-SlC2LM',
    'NNP3BG15-SlC3LK',
    'NNP3BG15-SlC3LM',
    'NNP3BG15-SlC4LK',
    'NNP3BG15-SlC4LM',
    'NNP3BG15-SlC5LK',
    'NNP3BG15-SlC5LM',
    'NNP3BG15-SlC6LK',
    'NNP3BG15-SlC6LM',
    'NNP3BG15-SlC7LK',
    'NNP3BG15-SlC7LM',
    'NNP3BG15-SlC8LK',
    'NNP3BG15-SlC8LM',
    'SCP4BG15-SDC1LM',
    'SCP4BG15-SDC2LK',
    'SCP4BG15-SDC2LM',
    'SCP4BG15-SDC3LK',
    'SCP4BG15-SDC4LK',
    'SCP4BG15-SDC4LM',
    'SCP4BG15-SDC5LK',
    'SCP4BG15-SDC5LM',
    'SCP4BG15-SDC6LK',
    'SCP4BG15-SDC6LM',
    'SCP4BG15-SDC7LM',
    'SCP4BG15-SDC8LK',
    'SCP4BG15-SDC8LM',
    'SCP4BG15-SGC1LK',
    'SCP4BG15-SGC1LM',
    'SCP4BG15-SGC2LK',
    'SCP4BG15-SGC2LM',
    'SCP4BG15-SGC3LK',
    'SCP4BG15-SGC3LM',
    'SCP4BG15-SGC4LK',
    'SCP4BG15-SGC4LM',
    'SCP4BG15-SGC5LK',
    'SCP4BG15-SGC5LM',
    'SCP4BG15-SGC6LK',
    'SCP4BG15-SGC6LM',
    'SCP4BG15-SGC7LK',
    'SCP4BG15-SGC7LM',
    'SCP4BG15-SGC8LK',
    'SCP4BG15-SGC8LM',
    'SCP4BG15-SLC1LK',
    'SCP4BG15-SLC1LM',
    'SCP4BG15-SLC2LK',
    'SCP4BG15-SLC2LM',
    'SCP4BG15-SLC3LK',
    'SCP4BG15-SLC3LM',
    'SCP4BG15-SLC4LK',
    'SCP4BG15-SLC4LM',
    'SCP4BG15-SLC5LK',
    'SCP4BG15-SLC5LM',
    'SCP4BG15-SLC6LK',
    'SCP4BG15-SLC6LM',
    'SCP4BG15-SLC7LK',
    'SCP4BG15-SLC7LM',
    'SCP4BG15-SLC8LK',
    'SCP4BG15-SLC8LM',
    'SEP5BG0-SDC1LK',
    'SEP5BG0-SDC1LM',
    'SEP5BG0-SDC2LK',
    'SEP5BG0-SDC2LM',
    'SEP5BG0-SDC3LK',
    'SEP5BG0-SDC3LM',
    'SEP5BG0-SDC4LK',
    'SEP5BG0-SDC4LM',
    'SEP5BG0-SDC5LM',
    'SEP5BG0-SDC6LM',
    'SEP5BG0-SDC7LK',
    'SEP5BG0-SDC7LM',
    'SEP5BG0-SDC8LM',
    'SEP5BG0-SGC1LK',
    'SEP5BG0-SGC1LM',
    'SEP5BG0-SGC2LK',
    'SEP5BG0-SGC2LM',
    'SEP5BG0-SGC3LK',
    'SEP5BG0-SGC3LM',
    'SEP5BG0-SGC4LK',
    'SEP5BG0-SGC4LM',
    'SEP5BG0-SGC5LK',
    'SEP5BG0-SGC5LM',
    'SEP5BG0-SGC6LK',
    'SEP5BG0-SGC6LM',
    'SEP5BG0-SGC7LK',
    'SEP5BG0-SGC7LM',
    'SEP5BG0-SGC8LK',
    'SEP5BG0-SGC8LM',
    'SEP5BG0-SLC1Lk',
    'SEP5BG0-SLC1LM',
    'SEP5BG0-SLC2LK',
    'SEP5BG0-SLC2LM',
    'SEP5BG0-SLC3LK',
    'SEP5BG0-SLC3LM',
    'SEP5BG0-SLC4LK',
    'SEP5BG0-SLC4LM',
    'SEP5BG0-SLC5LK',
    'SEP5BG0-SLC5LM',
    'SEP5BG0-SLC6LK',
    'SEP5BG0-SLC6LM',
    'SEP5BG0-SLC7LK',
    'SEP5BG0-SLC7LM',
    'SEP5BG0-SLC8LK',
    'TMP4BG12-SDC1LK',
    'TMP4BG12-SDC1LM',
    'TMP4BG12-SDC2LK',
    'TMP4BG12-SDC2LM',
    'TMP4BG12-SDC3LK',
    'TMP4BG12-SDC3LM',
    'TMP4BG12-SDC4LK',
    'TMP4BG12-SDC4LM',
    'TMP4BG12-SDC5LM',
    'TMP4BG12-SDC6LK',
    'TMP4BG12-SDC6LM',
    'TMP4BG12-SDC7LK',
    'TMP4BG12-SDC8LK',
    'TMP4BG12-SDC8LM',
    'TMP4BG12-SGC1LK',
    'TMP4BG12-SGC1LM',
    'TMP4BG12-SGC2LK',
    'TMP4BG12-SGC2LM',
    'TMP4BG12-SGC3LK',
    'TMP4BG12-SGC3LM',
    'TMP4BG12-SGC4LK',
    'TMP4BG12-SGC4LM',
    'TMP4BG12-SGC5LK',
    'TMP4BG12-SGC5LM',
    'TMP4BG12-SGC6LK',
    'TMP4BG12-SGC6LM',
    'TMP4BG12-SGC7LK',
    'TMP4BG12-SGC7LM',
    'TMP4BG12-SGC8LK',
    'TMP4BG12-SGC8LM',
    'TMP4BG12-SLC1LK',
    'TMP4BG12-SLC1LM',
    'TMP4BG12-SLC2LK',
    'TMP4BG12-SLC2LM',
    'TMP4BG12-SLC3LK',
    'TMP4BG12-SLC3LM',
    'TMP4BG12-SLC4LK',
    'TMP4BG12-SLC4LM',
    'TMP4BG12-SLC5LK',
    'TMP4BG12-SLC5LM',
    'TMP4BG12-SLC6LK',
    'TMP4BG12-SLC6LM',
    'TMP4BG12-SLC7LK',
    'TMP4BG12-SLC7LM',
    'TMP4BG12-SLC8LK',
    'TMP4BG12-SLC8LM',
    'ILP1BG1-SDC1LM',
    'ILP1BG1-SDC2LK',
    'ILP1BG1-SDC3LM',
    'ILP1BG1-SDC4LK',
    'ILP1BG1-SDC4LM',
    'ILP1BG1-SDC5LM',
    'ILP1BG1-SDC6LK',
    'ILP1BG1-SDC6LM',
    'ILP1BG1-SDC7LK',
    'ILP1BG1-SDC7LM',
    'ILP1BG1-SDC8LK',
    'ILP1BG1-SDC8LM',
    'ILP1BG1-SGC1LK',
    'ILP1BG1-SGC1LM',
    'ILP1BG1-SGC2LM',
    'ILP1BG1-SGC3LK',
    'ILP1BG1-SGC3LM',
    'ILP1BG1-SGC4LK',
    'ILP1BG1-SGC4LM',
    'ILP1BG1-SGC5LK',
    'ILP1BG1-SGC5LM',
    'ILP1BG1-SGC6LK',
    'ILP1BG1-SGC6LM',
    'ILP1BG1-SGC7LK',
    'ILP1BG1-SGC7LM',
    'ILP1BG1-SGC8LK',
    'ILP1BG1-SGC8LM',
    'ILP1BG1-SLC1LK',
    'ILP1BG1-SLC1LM',
    'ILP1BG1-SLC2LK',
    'ILP1BG1-SLC2LM',
    'ILP1BG1-SLC3LK',
    'ILP1BG1-SLC3LM',
    'ILP1BG1-SLC4LK',
    'ILP1BG1-SLC4LK2',
    'ILP1BG1-SLC5LK',
    'ILP1BG1-SLC5LM',
    'ILP1BG1-SLC6LK',
    'ILP1BG1-SLC6LM2',
    'ILP1BG1-SLC7LK',
    'ILP1BG1-SLC7LM',
    'ILP1BG1-SLC8LK',
    'ILP1BG1-SLC8LM',
    'ILP1BG5-SDC1LK',
    'ILP1BG5-SDC1LM',
    'ILP1BG5-SDC2LK',
    'ILP1BG5-SDC2LM',
    'ILP1BG5-SDC3LK',
    'ILP1BG5-SDC3LM',
    'ILP1BG5-SDC4LK',
    'ILP1BG5-SDC4LM',
    'ILP1BG5-SDC5LK',
    'ILP1BG5-SDC5LM',
    'ILP1BG5-SDC6LK',
    'ILP1BG5-SDC6LM',
    'ILP1BG5-SDC8LK',
    'ILP1BG5-SDC8LM',
    'ILP1BG5-SGC1LK',
    'ILP1BG5-SGC1LM',
    'ILP1BG5-SGC2LK',
    'ILP1BG5-SGC2LM',
    'ILP1BG5-SGC3LK',
    'ILP1BG5-SGC3LM',
    'ILP1BG5-SGC4LK',
    'ILP1BG5-SGC4LM',
    'ILP1BG5-SGC5LK',
    'ILP1BG5-SGC5LM',
    'ILP1BG5-SGC6LK',
    'ILP1BG5-SGC6LM',
    'ILP1BG5-SGC7LK',
    'ILP1BG5-SGC7LM',
    'ILP1BG5-SGC8LK',
    'ILP1BG5-SGC8LM',
    'ILP2BG2-SDC1LK',
    'ILP2BG2-SDC1LM',
    'ILP2BG2-SDC2LM',
    'ILP2BG2-SDC3LK',
    'ILP2BG2-SDC3LM',
    'ILP2BG2-SDC4LK',
    'ILP2BG2-SDC4LM',
    'ILP2BG2-SDC5LK',
    'ILP2BG2-SDC5LM',
    'ILP2BG2-SDC6LK',
    'ILP2BG2-SDC6LM',
    'ILP2BG2-SDC7LK',
    'ILP2BG2-SDC7LM',
    'ILP2BG2-SDC8LK',
    'ILP2BG2-SDC8LM',
    'ILP2BG2-SGC1Lk',
    'ILP2BG2-SGC1LM',
    'ILP2BG2-SGC2LK',
    'ILP2BG2-SGC2LM',
    'ILP2BG2-SGC3LK',
    'ILP2BG2-SGC3LM',
    'ILP2BG2-SGC4LK',
    'ILP2BG2-SGC4LM',
    'ILP2BG2-SGC5LK',
    'ILP2BG2-SGC5LM',
    'ILP2BG2-SGC6LK',
    'ILP2BG2-SGC6LM',
    'ILP2BG2-SGC7LK',
    'ILP2BG2-SGC7LM',
    'ILP2BG2-SGC8LK',
    'ILP2BG2-SGC8LM',
    'ILP2BG3-SDC1LK',
    'ILP2BG3-SDC1LM',
    'ILP2BG3-SDC2LK',
    'ILP2BG3-SDC2LM',
    'ILP2BG3-SDC3LK',
    'ILP2BG3-SDC3LM',
    'ILP2BG3-SDC4LK',
    'ILP2BG3-SDC4LM',
    'ILP2BG3-SDC5LK',
    'ILP2BG3-SDC5LM',
    'ILP2BG3-SDC6LK',
    'ILP2BG3-SDC6LM',
    'ILP2BG3-SDC7LK',
    'ILP2BG3-SDC7LM',
    'ILP2BG3-SDC8LK',
    'ILP2BG3-SDC8LM',
    'ILP2BG3-SGC1LK',
    'ILP2BG3-SGC1LM',
    'ILP2BG3-SGC2LK',
    'ILP2BG3-SGC2LM',
    'ILP2BG3-SGC3LK',
    'ILP2BG3-SGC3LM',
    'ILP2BG3-SGC4LK',
    'ILP2BG3-SGC4LM',
    'ILP2BG3-SGC5LK',
    'ILP2BG3-SGC5LM',
    'ILP2BG3-SGC6LK',
    'ILP2BG3-SGC6LM',
    'ILP2BG3-SGC7LK',
    'ILP2BG3-SGC7LM',
    'ILP2BG3-SGC8LK',
    'ILP2BG3-SGC8LM',
    'ILP2BG3-SLC1LK',
    'ILP2BG3-SLC1LM',
    'ILP2BG3-SLC2LK',
    'ILP2BG3-SLC2LM',
    'ILP2BG3-SLC3LK',
    'ILP2BG3-SLC3LM',
    'ILP2BG3-SLC4LK',
    'ILP2BG3-SLC4LM',
    'ILP2BG3-SLC5LK',
    'ILP2BG3-SLC5LM',
    'ILP2BG3-SLC6LK',
    'ILP2BG3-SLC6LM',
    'ILP2BG3-SLC7LK',
    'ILP2BG3-SLC7LM',
    'ILP2BG3-SLC8LK',
    'ILP2BG3-SLC8LM',
    'ILP2BG4-SVC1LK',
    'ILP2BG4-SVC1LM',
    'ILP2BG4-SVC2LK',
    'ILP2BG4-SVC2LM',
    'ILP2BG4-SVC3LK',
    'ILP2BG4-SVC3LM',
    'ILP2BG4-SVC4LK',
    'ILP2BG4-SVC4LM',
    'ILP2BG4-SVC5LK',
    'ILP2BG4-SVC5LM',
    'ILP2BG4-SVC6LK',
    'ILP2BG4-SVC6LM',
    'ILP2BG4-SVC7LK',
    'ILP2BG4-SVC7LM',
    'ILP2BG4-SVC8LK',
    'ILP2BG4-SVC8LM',
    'ILP2BG8-SLC1LK',
    'ILP2BG8-SLC1LM',
    'ILP2BG8-SLC2LK',
    'ILP2BG8-SLC2LM',
    'ILP2BG8-SLC3LK',
    'ILP2BG8-SLC3LM',
    'ILP2BG8-SLC4LK',
    'ILP2BG8-SLC4LM',
    'ILP2BG8-SLC5LK',
    'ILP2BG8-SLC5LM',
    'ILP2BG8-SLC6LK',
    'ILP2BG8-SLC6LM',
    'ILP2BG8-SLC7LK',
    'ILP2BG8-SLC7LM',
    'ILP2BG8-SLC8LK',
    'ILP2BG8-SLC8LM',
    'ILP2BG9-SGC1LK',
    'ILP2BG9-SGC1LM',
    'ILP2BG9-SGC2LK',
    'ILP2BG9-SGC2LM',
    'ILP2BG9-SGC3LK',
    'ILP2BG9-SGC3LM',
    'ILP2BG9-SGC4LK',
    'ILP2BG9-SGC4LM',
    'ILP2BG9-SGC5LK',
    'ILP2BG9-SGC5LM',
    'ILP2BG9-SGC6LK',
    'ILP2BG9-SGC6LM',
    'ILP2BG9-SGC7LK',
    'ILP2BG9-SGC7LM',
    'ILP2BG9-SGC8LK',
    'ILP2BG9-SGC8LM',
    'SCP1BG13-SDC1LK',
    'SCP1BG13-SDC1LM',
    'SCP1BG13-SDC2LK',
    'SCP1BG13-SDC2LM',
    'SCP1BG13-SDC3LK',
    'SCP1BG13-SDC3LM',
    'SCP1BG13-SDC4LK',
    'SCP1BG13-SDC4LM',
    'SCP1BG13-SDC5LK',
    'SCP1BG13-SDC5LM',
    'SCP1BG13-SDC6LK',
    'SCP1BG13-SDC6LM',
    'SCP1BG13-SDC7LK',
    'SCP1BG13-SDC7LM',
    'SCP1BG13-SDC8LK',
    'SCP1BG13-SDC8LM',
    'SCP1BG13-SGC1LK',
    'SCP1BG13-SGC1LM',
    'SCP1BG13-SGC2LK',
    'SCP1BG13-SGC2LM',
    'SCP1BG13-SGC3LK',
    'SCP1BG13-SGC3LM',
    'SCP1BG13-SGC4LK',
    'SCP1BG13-SGC4LM',
    'SCP1BG13-SGC5LK',
    'SCP1BG13-SGC5LM',
    'SCP1BG13-SGC6LK',
    'SCP1BG13-SGC6LM',
    'SCP1BG13-SGC7LK',
    'SCP1BG13-SGC7LM',
    'SCP1BG13-SGC8LK',
    'SCP1BG13-SGC8LM',
    'SCP1BG14-SDC1LK',
    'SCP1BG14-SDC1LM',
    'SCP1BG14-SDC2LK',
    'SCP1BG14-SDC2LM',
    'SCP1BG14-SDC3LK',
    'SCP1BG14-SDC3LM',
    'SCP1BG14-SDC4LK',
    'SCP1BG14-SDC4LM',
    'SCP1BG14-SDC5LK',
    'SCP1BG14-SDC5LM',
    'SCP1BG14-SDC6LK',
    'SCP1BG14-SDC6LM',
    'SCP1BG14-SDC7LK',
    'SCP1BG14-SDC7LM',
    'SCP1BG14-SDC8LK',
    'SCP1BG14-SDC8LM',
    'SCP1BG14-SGC1LK',
    'SCP1BG14-SGC1LM',
    'SCP1BG14-SGC2LK',
    'SCP1BG14-SGC2LM',
    'SCP1BG14-SGC3LK',
    'SCP1BG14-SGC3LM',
    'SCP1BG14-SGC4LK',
    'SCP1BG14-SGC4LM',
    'SCP1BG14-SGC5LK',
    'SCP1BG14-SGC5LM',
    'SCP1BG14-SGC6LK',
    'SCP1BG14-SGC6LM',
    'SCP1BG14-SGC7LK',
    'SCP1BG14-SGC7LM',
    'SCP1BG14-SGC8LK',
    'SCP1BG14-SGC8LM',
    'SCP1BG15-SDC1LK',
    'SCP1BG15-SDC1LM',
    'SCP1BG15-SDC2LK',
    'SCP1BG15-SDC2LM',
    'SCP1BG15-SDC3LK',
    'SCP1BG15-SDC3LM',
    'SCP1BG15-SDC4LK',
    'SCP1BG15-SDC4LM',
    'SCP1BG15-SDC5LK',
    'SCP1BG15-SDC5LM',
    'SCP1BG15-SDC6LK',
    'SCP1BG15-SDC6LM',
    'SCP1BG15-SDC7LK',
    'SCP1BG15-SDC7LM',
    'SCP1BG15-SDC8LK',
    'SCP1BG15-SDC8LM',
    'SCP1BG15-SGC1LK',
    'SCP1BG15-SGC1LM',
    'SCP1BG15-SGC2LK',
    'SCP1BG15-SGC2LM',
    'SCP1BG15-SGC3LK',
    'SCP1BG15-SGC3LM',
    'SCP1BG15-SGC4LK',
    'SCP1BG15-SGC4LM',
    'SCP1BG15-SGC5LK',
    'SCP1BG15-SGC5LM',
    'SCP1BG15-SGC6LK',
    'SCP1BG15-SGC6LM',
    'SCP1BG15-SGC7LK',
    'SCP1BG15-SGC7LM',
    'SCP1BG15-SGC8LK',
    'SCP1BG15-SGC8LM',
    'SCP1BG15-SLC1LK',
    'SCP1BG15-SLC1LM',
    'SCP1BG15-SLC2LK',
    'SCP1BG15-SLC2LM',
    'SCP1BG15-SLC3LK',
    'SCP1BG15-SLC3LM',
    'SCP1BG15-SLC4LK',
    'SCP1BG15-SLC4LM',
    'SCP1BG15-SLC5LK',
    'SCP1BG15-SLC5LM',
    'SCP1BG15-SLC6LK',
    'SCP1BG15-SLC6LM',
    'SCP1BG15-SLC7LK',
    'SCP1BG15-SLC7LM',
    'SCP1BG15-SLC8LK',
    'SCP1BG15-SLC8LM',
    'SCP1BG16-SDC1LK',
    'SCP1BG16-SDC1LM',
    'SCP1BG16-SDC2LK',
    'SCP1BG16-SDC2LM',
    'SCP1BG16-SDC3LK',
    'SCP1BG16-SDC3LM',
    'SCP1BG16-SDC4LK',
    'SCP1BG16-SDC4LM',
    'SCP1BG16-SDC5LK',
    'SCP1BG16-SDC5LM',
    'SCP1BG16-SDC6LK',
    'SCP1BG16-SDC6LM',
    'SCP1BG16-SDC7LK',
    'SCP1BG16-SDC7LM',
    'SCP1BG16-SDC8LK',
    'SCP1BG16-SDC8LM',
    'SCP1BG16-SGC1LK',
    'SCP1BG16-SGC1LM',
    'SCP1BG16-SGC2LK',
    'SCP1BG16-SGC2LM',
    'SCP1BG16-SGC3LK',
    'SCP1BG16-SGC3LM',
    'SCP1BG16-SGC4LK',
    'SCP1BG16-SGC4LM',
    'SCP1BG16-SGC5LK',
    'SCP1BG16-SGC5LM',
    'SCP1BG16-SGC6LK',
    'SCP1BG16-SGC6LM',
    'SCP1BG16-SGC7LK',
    'SCP1BG16-SGC7LM',
    'SCP1BG16-SGC8LK',
    'SCP1BG16-SGC8LM',
    'SCP1BG16-SLC1LK',
    'SCP1BG16-SLC1LM',
    'SCP1BG16-SLC2LK',
    'SCP1BG16-SLC2LM',
    'SCP1BG16-SLC3LK',
    'SCP1BG16-SLC3LM',
    'SCP1BG16-SLC4LK',
    'SCP1BG16-SLC4LM',
    'SCP1BG16-SLC5LK',
    'SCP1BG16-SLC5LM',
    'SCP1BG16-SLC6LK',
    'SCP1BG16-SLC6LM',
    'SCP1BG16-SLC7LK',
    'SCP1BG16-SLC7LM',
  ]

  const closeModal = () => {
    setIsOpen(false)
  }

  const enumerate = () => {
    let enumeration = id
    if (id.length === 1) enumeration = '00' + id
    else if (id.length === 2) enumeration = '0' + id
    console.log(enumeration)
    return enumeration
  }

  if (id > 750 || id < 1) {
    return <Navigate replace to="/" />
  } else {
    return (
      <div className="container" style={{ backgroundColor: '' }}>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button
            onClick={closeModal}
            style={{
              border: 'none',
              background: 'transparent',
              margin: 0,
              position: 'absolute',
              opacity: 1,
              zIndex: 10,
              cursor: 'pointer',
              top: 10,
              right: -10,
              marginRigth: 50,
              float: 'right',
            }}
          >
            <AiOutlineCloseSquare
              color="white"
              size="25"
              style={{ marginRight: 10 }}
            />
          </button>
          <p
            style={{
              marginTop: -5,
              marginBottom: -10,
              marginRight: 15,
              textAlign: 'center',
            }}
          >
            Size özel hazırlanan bu esere sahip olmanız için, hediyenizde yer
            alan siyah zarf içindeki yönergeyi takip etmenizi rica ederiz.
          </p>
        </Modal>

        <div className="capsule-div">
          <a href="https://test.rmk-museum-nft.com">
            <img id="tablet-image" src={Group30} alt="" />
          </a>
          <a href="https://rmk-museum-nft.com">
            <img
              id="mobile-image"
              src={New}
              alt=""
              style={{ width: '385px', height: 'auto' }}
            />
          </a>
        </div>
        <div className="custom-info-div" style={{ backgroundColor: '' }}>
          <div
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img
              className="nft1"
              id="tablet-image"
              alt=""
              src={require(`./assets/nft_images/${id}.jpg`)}
              style={{ width: '100%' }}
            />
            <img
              className="nft1"
              alt=""
              id="mobile-image"
              src={require(`./assets/nft_images/${id}.jpg`)}
              style={{
                width: '200px',
                height: '200px',
              }}
            />
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1 style={{ fontSize: 30 }}>{nftNames[id - 1]}</h1>
            <h3>{enumerate()}/750</h3>
            <a href="https://rmk-museum-nft.com">
              <button
                style={{
                  marginTop: 10,
                  marginBottom: -10,
                  color: '#000000',
                  backgroundColor: '#ffffff',
                  border: '1px solid #000000',
                  borderRadius: 5,
                  fontSize: 14,
                  fontWeight: 'light',
                }}
              >
                <p
                  style={{
                    marginLeft: 7,
                    marginRight: 7,
                    marginTop: 5,
                    marginBottom: 5,
                    letterSpacing: 1,
                  }}
                >
                  Websiteyi ziyaret edin
                </p>
              </button>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default Page1
